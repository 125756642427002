import { useState } from "react";
import Spinner from 'react-bootstrap/Spinner';
import {
  GoogleMap,
  useLoadScript,
  Marker,
  InfoWindow,
} from "@react-google-maps/api";
import * as memorableLocations from "../data/memorable-locations.json";
import { MemorableLocation } from "../models/memorableLocation"
import { v4 as uuidv4 } from "uuid";
import React from "react";

// Props
export type LocationsMapProps = {
    googleMapsApiKey: string;
}

// Configuration
const mapContainerStyle = {
  height: "100vh",
  width: "100vw"
};

const center = {
  lat: 54.788267,
  lng: -2.343188
};

const zoom = 6;

// Loading Spinner
function spinner() {
  return (
    <Spinner animation="border" role="status">
      <span className="visually-hidden">Loading...</span>
    </Spinner>
  );
}

// The Map
export function LocationsMap( { googleMapsApiKey }: LocationsMapProps) {
  
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: googleMapsApiKey
  });
  
  const [selectedMemorableLocation, setSelectedMemorableLocation] = useState<MemorableLocation | null>(null);

  const renderMap = () => {
    
    return (
      <GoogleMap
        id="map"
        mapContainerStyle={mapContainerStyle}
        zoom={zoom}
        center={center}
      >
        {memorableLocations.locations.map(memorableLocation => {
          
          const memorableLocationObject: MemorableLocation = memorableLocation as MemorableLocation;
          
          return(<Marker
            key={uuidv4()}
            position={{
              lat: memorableLocationObject.latitude,
              lng: memorableLocationObject.longitude
            }}
            onClick={() => {
              setSelectedMemorableLocation(memorableLocationObject);
            }}
          />)
        })}

        {selectedMemorableLocation && (
          <InfoWindow
            onCloseClick={() => {
              setSelectedMemorableLocation(null);
            }}
            position={{
              lat: selectedMemorableLocation.latitude,
              lng: selectedMemorableLocation.longitude
            }}
          >
            <div>
              <h2>{selectedMemorableLocation.name}</h2>
              <p>{selectedMemorableLocation.description}</p>
            </div>
          </InfoWindow>
        )}
      </GoogleMap>
    );
  }
  
  if (loadError) {
    return <div>Map cannot be loaded right now, sorry.</div>
  }

  return isLoaded ? renderMap() : spinner()
}
