import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './components/App';
import './setup/firebase';
import { SignIn } from './components/SignIn';

ReactDOM.render(
  <React.StrictMode>
    <SignIn app = {<App />} />
  </React.StrictMode>,
  document.getElementById('root')
);
